import React, { Component } from 'react';
import ScrollView from '../../../ScrollView';
import { accessoryData } from './data';
import ModelCards from '../ModelCards';
import './accessories.style.css';
import { sliderSettings } from '../../../ScrollView/sliderSettings';
import { accessories as accessoryDataForSKU } from '../../../../helpers/trampoline-accessories';

export default class Accessories extends Component {
  state = {
    accessoryData,
    click: true,
    activeIndex: 0,
    statusResults: [
      { "code": 1, "message": "" }, 
      { "code": 2, "message": "No shipment date currently available. Call 1 (877) 586-7723 for more info." }
    ]
  };

  /**
   * select the accessories
   * and move the data to the parent data store
   */
  selectAccessories(selection) {
    if (!this.state.click) {
      return false;
    }
    let { selectedAccessories, updateSelectedAccessories } = this.props;
    if (selectedAccessories.includes(selection.id)) {
      const indexToRemove = selectedAccessories.indexOf(selection.id);
      selectedAccessories.splice(indexToRemove, 1);
    } else {
      selectedAccessories.push(selection.id);
    }
    // console.log(selectedAccessories)
    updateSelectedAccessories(selectedAccessories);
  }

  render() {
    const { selectedAccessories, activeProductId, location, activeProductDetails } = this.props;
    const product = activeProductId;

    return (
      <div className="accessories__wrapper">        
        <ScrollView
          heading="Add accessories."
          settings={sliderSettings(this)}
          activeIndex={this.state.activeIndex}
        >
          {
            this.state.accessoryData.map((accessory, index) => {
              let accSkuId = accessoryDataForSKU[activeProductDetails['id']][accessory.id];

              if(accSkuId)
              {
                let accessoryStatusIndex = activeProductDetails['accessories'].findIndex(p => p.sku === accSkuId);

                if(accessoryStatusIndex >= 0)
                {
                  let accessoryStatus = activeProductDetails['accessories'][accessoryStatusIndex]['status'];

                  accessory['status'] = accessoryStatus;

                  return (
                    <ModelCards
                      key={accessory.name}
                      cardIndex={index}
                      type="accessories"
                      price={accessory[location][product]}
                      activeIndex={selectedAccessories.includes(accessory.id) ? index : -1}
                      selectIndex={() => this.selectAccessories(accessory)}
                      accessoryStatus={accessoryStatus}
                      {...accessory}
                    />
                  );
                }
              }
              return true;
            }
          )}
        </ScrollView>
      </div>
    );
  }
}
