import { partArrangements } from './partArrangements';

/**
 * Creates email data with the all the arrangements and color information
 * @param {string} productId - id of the selected product
 * @param {object} spec - object with part detail and colors
 */
const getEmailData = (productId, spec) => {
  let emailData = partArrangements(productId, spec);
  return emailData;
};

export default getEmailData;
