import { spec } from "./defaultSpec";
import { productData } from "../Components/Screens/Data";
import { themes } from "../Components/Screens/Components/ProductSelector/themes";
import { resetSpecialCharacter } from "./parseSpecialCharacter";

/**
 * with the url as input the function will return the structured JSON
 * @param {string} query - the parameter information from the url
 */
export const parseUrl = (query) => {
  let parsedOutput = {
    status: true,
    location: "us",
    selectedAccessories: [],
    activeStep: 4, // default active step for order page
    activeCustom: 2,
    customizationOption: "",
    selectedTheme: "",
    activeIndex: 3,
    activeProductId: "",
    activeProduct: {},
    order: true,
    steps: [],
    fontDetail: {
      activeFont: "",
      fontText: "",
      fontSize: 22,
      lineOne: "",
      lineTwo: "",
      color: "",
      isCustom: false,
    },
    spec: spec,
    orderId: null,
    dealer: false,
    selectedModel: ""
  };
  // get the id
  const id = query.split("?");
  for (let i = 0;i < id.length;i++) {
    if (id[i].includes("dealer")) {
      parsedOutput.dealer = true;
    }
    else if (id[i].includes("model")) {
      var res = id[i].split("=");
      parsedOutput.selectedModel = res[1];
    }
  }

  if (!parsedOutput.dealer && !parsedOutput.selectedModel) {
    const idParams = id[1].split("");

    /**
     * Get the product Id and customization type
     */
    let product = "";
    let type = "";
    let theme = "";
    let pointer = []; // to keep track of &
    // eslint-disable-next-line no-unused-vars
    for (let characterVal of idParams) {
      if (characterVal === "&") {
        pointer.push("&");
        let breakValue = 3;
        if (type === "custom") {
          breakValue = 2;
        }
        if (pointer.length === breakValue) {
          break;
        }
        continue;
      }

      if (pointer.length === 1) {
        type += characterVal;
        continue;
      }

      if (type === "theme") {
        theme += characterVal;
        continue;
      }

      product += characterVal;
    }
    parsedOutput.activeProductId = product;
    parsedOutput.activeProduct = productData.find(
      (prod) => prod.id === product
    );
    /**
     * if not product was found matching the id
     * make the status false
     */
    if (!parsedOutput.activeProduct) {
      parsedOutput.status = false;
    }

    parsedOutput.customizationOption = type;
    parsedOutput.selectedTheme = theme;

    /**
     * Make the color spec of the parts
     * @param {object} activeSpec - specifiction of the active part
     * @param {number} activeSpec.start - starting position
     * @param {number} activeSpec.end - ending position
     * @param {string} targetPart - part to udpate
     */
    const parsePartSpec = ({ start, end }, targetPart) => {
      let partSpec = {};
      /**
       * add 1 extra to start to cover for the part targetPart id
       * add the arrangement
       */
      let arrangement = "";
      for (let valString = start + 1;valString < end;valString++) {
        if (idParams[valString] === "=") {
          break;
        }
        arrangement += idParams[valString];
      }

      /**
       * Add the value for part arrangement
       * add 1 more to counter the '='
       */
      const startVal = start + arrangement.length + 1;
      /**
       * if the url is not formatted properly
       * there are 6 colors so the string length should be 36
       * compunsate for index values
       */
      if (end - 1 - (startVal + 1) !== 36) {
        parsedOutput.status = false;
      }

      // variables to store values of 6 colors
      let vOne = "",
        vTwo = "",
        vThree = "",
        vFour = "",
        vFive = "",
        vSix = "";
      for (let valColor = startVal + 1;valColor < end;valColor++) {
        // first color
        if (valColor < startVal + 7) {
          vOne += idParams[valColor];
        }

        // second color
        if (valColor > startVal + 6 && valColor < startVal + 13) {
          vTwo += idParams[valColor];
        }

        // third color
        if (valColor > startVal + 12 && valColor < startVal + 19) {
          vThree += idParams[valColor];
        }

        // forth color
        if (valColor > startVal + 18 && valColor < startVal + 25) {
          vFour += idParams[valColor];
        }

        // fifth color
        if (valColor > startVal + 24 && valColor < startVal + 31) {
          vFive += idParams[valColor];
        }

        // sixth color
        if (valColor > startVal + 30 && valColor < startVal + 37) {
          vSix += idParams[valColor];
        }
      }

      partSpec = {
        arrangement: arrangement,
      };

      partSpec[`${targetPart.toLowerCase()}1`] = vOne;
      partSpec[`${targetPart.toLowerCase()}2`] = vTwo;
      partSpec[`${targetPart.toLowerCase()}3`] = vThree;
      partSpec[`${targetPart.toLowerCase()}4`] = vFour;
      partSpec[`${targetPart.toLowerCase()}5`] = vFive;
      partSpec[`${targetPart.toLowerCase()}6`] = vSix;

      return partSpec;
    };

    /**
     * Get plaque font details from the url
     * check for font keys and value in them
     */
    const parsePlaque = () => {
      let plaqueDetail = {
        activeFont: "",
        fontSize: "",
        lineOne: "",
        lineTwo: "",
        color: "",
        plaqueColor: "",
        isCustom: "",
      };
      /**
       * Add 3 to the position
       * 3 for '&PL' and 1 for ='
       * -1 for '?'
       */
      const startPosition = query.indexOf("&PL") + 3;
      let pointer = 0;
      for (
        let plaqueVal = startPosition;
        plaqueVal < query.length - 1;
        plaqueVal++
      ) {
        if (idParams[plaqueVal] === "&") {
          break;
        }

        if (idParams[plaqueVal] === "-") {
          pointer += 1;
          continue;
        }
        if (pointer === 6) {
          plaqueDetail.plaqueColor += idParams[plaqueVal];
          continue;
        }
        if (pointer === 5) {
          plaqueDetail.color += idParams[plaqueVal];
          continue;
        }
        if (pointer === 4) {
          plaqueDetail.lineTwo += idParams[plaqueVal];
          continue;
        }
        if (pointer === 3) {
          plaqueDetail.lineOne += idParams[plaqueVal];
          continue;
        }
        if (pointer === 2) {
          plaqueDetail.fontSize += idParams[plaqueVal];
          continue;
        }
        if (pointer === 1) {
          plaqueDetail.activeFont += idParams[plaqueVal];
          continue;
        }
        if (pointer === 0) {
          plaqueDetail.isCustom += idParams[plaqueVal];
        }
      }

      // change status value to boolean from string
      plaqueDetail.isCustom = plaqueDetail.isCustom === "true";
      plaqueDetail.lineOne = resetSpecialCharacter(plaqueDetail.lineOne);
      plaqueDetail.lineTwo = resetSpecialCharacter(plaqueDetail.lineTwo);

      return plaqueDetail;
    };

    /**
     * Steps based on theme or custom
     * this is essential to navigate the interface
     */
    const parseSteps = () => {
      /**
       * If there is any issue with the url
       * do not process the steps
       * if it is custom parse and load customization settings
       */
      if (!parsedOutput.activeProduct) {
        return null;
      }

      let steps = parsedOutput.activeProduct.data;
      if (parsedOutput.customizationOption === "theme") {
        steps = [themes];
      }

      // load preset configuration for color customizer
      if (parsedOutput.customizationOption === "custom") {
        steps.forEach((step, index) => {
          const arrangementColorSpec = parsedOutput.spec[step.id].arrangement
            .toString()
            .split("");
          const arrangementColorValue = arrangementColorSpec[0];
          const arrangementColorCount = parseInt(arrangementColorSpec[0]);
          let arrangementIndex = 0;

          // get the number of colors and arrangement
          const arrangementOfColors = () => {
            let arrangement = ""; // if only one color is selected
            // if there is a multiple colors arrangment
            if (arrangementColorSpec.length === 1) {
              return "0000";
            } else if (arrangementColorSpec.length > 2) {
              // url is not formatted correctly
              parsedOutput.status = false;
            } else {
              const optionPosition = parseInt(arrangementColorSpec[1]) - 1;
              const fetchArrangement = parsedOutput.activeProduct.data[
                index
              ].config.find((conf) => conf.id === arrangementColorValue);

              arrangementIndex = parsedOutput.activeProduct.data[
                index
              ].config.findIndex((conf) => conf.id === arrangementColorValue);

              arrangement = fetchArrangement.options[optionPosition].id;
            }

            return `${arrangementColorValue}${arrangement}`;
          };

          // get color values
          const getColorValues = () => {
            let colorList = [];
            for (let c = 0;c < arrangementColorCount;c++) {
              const activeValueObject = parsedOutput.spec[step.id];
              colorList.push(
                `#${activeValueObject[`${step.id.toLowerCase()}${c + 1}`]}`
              );
            }

            return colorList;
          };

          // set value in steps
          step["selection"] = {
            arrangement: arrangementOfColors(), // call this before color, becaues it sets arrangementIndex
            color: arrangementIndex,
            pickedColors: getColorValues(),
          };
        });

        // make the active step for the order page
        parsedOutput.activeStep = 7;
      }

      return steps;
    };

    /**
     * Create the list of accessories
     * @param {number} startIndex - this will be the index of '&AL'
     * @return {array} - with all the accessory ids
     */
    const getAccessories = (startIndex) => {
      let listString = "";
      const endPosition = idParams.length;

      // add 3 to the start, to counter '&AL'
      for (
        let accCharPosition = startIndex + 3;
        accCharPosition < endPosition;
        accCharPosition++
      ) {
        if (idParams[accCharPosition] === "&") {
          // the parameter has changed
          break;
        }
        listString += idParams[accCharPosition];
      }

      if (listString.length > 0) {
        // there are selected accessories
        listString = listString.split("-");
      } else {
        // there are no accessories selected
        listString = [];
      }

      return listString;
    };

    /**
     * Fetch order id from the url
     * @param {number} startIndex - this will be the index of '&OI'
     * @return {string} - order id value
     */
    const getOrderId = (startIndex) => {
      // if the value doesn't exist it will be '-1'
      if (startIndex === -1) {
        return null;
      }
      let idOfTheOrder = "";

      const endPosition = idParams.length;

      // add 3 to the start, to counter '&OI'
      for (
        let orderPosition = startIndex + 3;
        orderPosition < endPosition;
        orderPosition++
      ) {
        if (idParams[orderPosition] === "&") {
          // the parameter has changed
          break;
        }
        idOfTheOrder += idParams[orderPosition];
      }

      return idOfTheOrder;
    };

    /**
     * Get the value of part arrangement and colors
     * set the start and end sequence of the string
     * set the part id
     */
    const legSpec = parsePartSpec(
      { start: query.indexOf("&L"), end: query.indexOf("&F") },
      "L"
    );
    const frameSpec = parsePartSpec(
      { start: query.indexOf("&F"), end: query.indexOf("&M") },
      "F"
    );
    const matSpec = parsePartSpec(
      { start: query.indexOf("&M"), end: query.indexOf("&E") },
      "M"
    );
    const encloserSpec = parsePartSpec(
      { start: query.indexOf("&E"), end: query.indexOf("&PL") },
      "E"
    );
    const listOfAccesssories = getAccessories(query.indexOf("&AL"));
    const orderId = getOrderId(query.indexOf("&OI"));

    // set theme and color values
    parsedOutput.spec.L = legSpec;
    parsedOutput.spec.F = frameSpec;
    parsedOutput.spec.M = matSpec;
    parsedOutput.spec.E = encloserSpec;
    parsedOutput.selectedAccessories = listOfAccesssories;
    parsedOutput.orderId = orderId;

    const plaqueInfo = parsePlaque();
    const steps = parseSteps();

    // set value in the main output object
    parsedOutput.fontDetail = plaqueInfo;
    parsedOutput.steps = steps;
  } else if (parsedOutput.selectedModel) {
    parsedOutput = {
      selectedModel: parsedOutput.selectedModel
    }
    return parsedOutput
  }
  else {
    parsedOutput = {
      dealer: true,
    };

    return parsedOutput;
  }

  return parsedOutput;
};
