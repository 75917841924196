import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import TagManager from "react-gtm-module";
import ReactGA from 'react-ga';


// import PinCheck from "./Components/PinCheck";
import Screens from "./Components/Screens/";
import NotFound from "./Components/NotFound";
import "./App.css";
import { lang } from "./helpers/language";

export default class App extends Component {
  state = {
    pinCheck: true,
    pin: "",
    skipPin: false,
    contactDetail: null,
    listOfPins: ["12345", "34567", "09876"],
    location: "us",
  };

  // udpate to skip pin or not
  ifSkipPin(status, contactDetail) {
    this.setState({
      skipPin: true,
      contactDetail,
    });
  }

  // Update the state of the pin
  checkPin(result, pin) {
    this.setState({
      pinCheck: result,
      pin,
    });
  }



  componentDidMount() {
    // check the location of the website
    const activeUrl = window.location.href;
    let locationFromUrl = "us";
    if (activeUrl.includes("springfreetrampoline.ca")) {
      locationFromUrl = "ca";

      this.setState({
        location: "ca",
      });
    }

    // initialize tag manager
    const tagManagerArgs = {
      gtmId: lang.tagManager[locationFromUrl],
    };

    TagManager.initialize(tagManagerArgs);
    ReactGA.initialize(lang.analyticsTracker[locationFromUrl]);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }



  render() {
    /**
     * NOTE: logic for trying out the tool
     * Send skipPin and contactDetail in state to order
     * if pin is skipped, the product cannot be shipped
     * disable shipping address in order when true
     */

    return (
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Redirect push to="/design" />} />
          <Route
            path="/design"
            component={(props) => {
              return (
                <Screens
                  {...props}
                  pin={this.state.pin}
                  skipPin={this.state.skipPin}
                  location={this.state.location}
                  contactDetail={this.state.contactDetail}
                />
              );
            }}
          />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}
