/**
 * Trampoline colors to get hex code
 */
export const colors = {
  black: "2a2a2d",
  gunmetal: "686666",
  white: "f0f4f0",
  red: "f11d20",
  green: "00bc38",
  blue: "0052b0",
  pink: "ff93c5",
};

export const colorsUrls = {
  "2a2a2d":
    "https://cdn.sayduck.io/a/shared_assets/files/001/175/962/54f528d38dc095cb4a66023b1134c0ad83c3c2a0e3648818a36dfe55e9e07ae5-original.jpg?v=1557836853&q=8",
  686666: "https://cdn.sayduck.io/a/shared_assets/files/002/152/471/c86392ae192568f741f15e0e60208ce12594ae994ea3b829508b254ee10f7db1-original.png",
  f0f4f0:
    "https://cdn.sayduck.io/a/shared_assets/files/002/151/966/4e8028852a6756958ea969e9621bfb4ce604406ee81ca1388b8ab5a8226f872d-original.png",
  f11d20:
    "https://cdn.sayduck.io/a/shared_assets/files/002/152/191/1ab4b62ec0e6e2cdc660cfa76526073fed2c9dcda1f39b09ec1b53c2b6de0086-original.png",
  "00bc38":
    "https://cdn.sayduck.io/a/shared_assets/files/002/152/370/1718eb181046490dd7865b0546a71d9e2b6fca7b99a0af63a381b98e9bb13ae3-original.png",
  "0052b0":
    "https://cdn.sayduck.io/a/shared_assets/files/002/152/339/d4d6b357f83065f88f7fe6acea20175169728a6bcd05bdd03fc90e8a9b97b0af-original.png",
  ff93c5:
    "https://cdn.sayduck.io/a/shared_assets/files/002/152/373/773e9f5fb5c2881a95e6a8423896730916b86a5d91e851113b34001a03b66df3-original.png",
};

/**
 * Trampoline colors to get color label from hex
 */
let colorKeys = Object.keys(colors);
let colorLabels = {};
colorKeys.forEach((color) => {
  colorLabels[colors[color]] = color;
});

export default colorLabels;
