// import React, { Component } from 'react';
import React from 'react';

const NotFound = () => {
  return (
    <div>
      <p>404, nothing found!</p>
    </div>
  );
};

export default NotFound;
