import { colors } from './trampolineColors';

export const spec = {
  L: {
    arrangement: 1,
    l1: colors.black,
    l2: colors.black,
    l3: colors.black,
    l4: colors.black,
    l5: colors.black,
    l6: colors.black,
  },
  F: {
    arrangement: 1,
    f1: colors.black,
    f2: colors.black,
    f3: colors.black,
    f4: colors.black,
    f5: colors.black,
    f6: colors.black,
  },
  M: {
    arrangement: 1,
    m1: colors.white,
    m2: colors.white,
    m3: colors.white,
    m4: colors.white,
    m5: colors.white,
    m6: colors.white,
  },
  E: {
    arrangement: 1,
    e1: colors.black,
    e2: colors.black,
    e3: colors.black,
    e4: colors.black,
    e5: colors.black,
    e6: colors.black,
  },
};
