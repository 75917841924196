// import React, { Component } from 'react';
import React from 'react';

const PlaqueLogo = ({ color }) => {
  return (
    <div className="plaque__item--logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="70%"
        viewBox="0 0 312.77 55.5"
        style={{
          fill: `#${color}`,
        }}
      >
        <defs>
          <path
            id="vbcdc"
            d="M63.2 35.46c-1.73 2.72-4.7 5.73-11.12 5.74-6.65.02-8.5-2.3-9.58-3.71-.03-.06-.17.02-.17.02l-5.25 15.65-12.61.04 9.68-29.28 1.63-8.67h12.45l.2 4.22.18-.04c2-1.4 5.2-4.13 12.31-4.16 6.48-.02 7.25 2.98 7.26 5.7.01 2.77-3.17 11.78-4.97 14.5M52.5 21.28c-2.16-.06-5.63 2.73-6.11 4.2l-1.73 5.15c-.48 1.4.5 4.45 2.59 4.53 3.2.19 4.73-3.43 5.07-4.34l1.66-4.88c.27-.91 1.72-4.54-1.48-4.66"
          ></path>
          <path
            id="vbcdd"
            d="M142.47 41.41h-13.79l4.68-14.1c.35-.9 1.58-4.59-1.68-4.58-2.09 0-5.35 3.01-5.83 4.47l-4.82 14.21-13.56.01 6.41-18.7 1.96-7.47h13.86l-1.03 4c2.1-1.4 5.35-4.05 12.53-4.07 6.48-.03 7.18 3.05 7.18 5.7 0 0-.05 2.69-5.91 20.53"
          ></path>
          <path
            id="vbcde"
            d="M177.84 41.1c-1.81 5.36-6.67 12.02-18.28 12.12-11.6.1-12.27-5.11-12.27-5.11 2.91.89 14.04 2.27 17.68-6.64.38-.77.93-2.76 1.32-3.86.03-.1-.07-.08-.13-.04-2.1 1.49-5.36 3.8-12.06 3.8-6.47.03-7.4-2.9-7.33-5.61.06-2.78 3.3-12.2 5.18-14.91 1.88-2.8 4.58-5.73 11.06-5.75 7.1-.02 8.63 2.61 9.69 4l1.47-3.85h12.25s-4.1 12.66-8.58 25.85m-9.88-20.16c-3.2 0-4.37 3.7-4.72 4.53l-2.28 5.44c-.42.84-1.51 4.53 1.76 4.53 2.09-.01 5.21-3.02 5.76-4.41l.7-1.67 1.8-4.89c-.15-1.53-1.28-3.54-3.02-3.53"
          ></path>
          <path
            id="vbcdf"
            d="M210.3 13.44c-.2 0-3.5-2.66-5.14 1.55l-.17.4h2.85l-2.1 5.72-2.58.01-7.18 20.29h-12.94L190 21.02h-3.36l1.9-5.57 3.41-.01.05-.21c2.9-8.58 10.48-11.24 17.16-11.26 2.58 0 4.4.54 4.81.67z"
          ></path>
          <path
            id="vbcdg"
            d="M244.74 29.54c-3.19 11.6 18.44 5.51 18.44 5.51s-4.22 6.04-16.4 6.45c-4.58-.2-16.16-1.26-16.18-11.22-.03-5.86 6.84-16.04 21.47-16.09 0 0 21.66-.07 13.76 15.28zm6.51-9.28s-4.6.01-5.77 6.07l8.92-.02c2.98-6.07-3.15-6.05-3.15-6.05z"
          ></path>
          <path
            id="vbcdh"
            d="M93.85 24.2s-9.9-3.13-13 5.48L76.82 41.4H64.44l6.42-18.56 1.49-7.6H83.4l-.1 3.34.22.01s4.07-2.97 5.96-3.4c3.46-.76 7.4.06 7.4.06z"
          ></path>
          <path id="vbcdi" d="M97.46 22.77l1.88-7.52h13.63l-9.18 26.14-12.9.05z"></path>
          <path
            id="vbcdj"
            d="M116.24 6.03c-.72 3.33-4.29 6.05-7.97 6.07-3.68 0-6.08-2.68-5.36-6.03.72-3.32 4.29-6.05 7.97-6.07 3.69 0 6.1 2.69 5.36 6.03"
          ></path>
          <path
            id="vbcdk"
            d="M21.06 23.9c.21-.86 0-1.61-.37-2.08-.64-.85-2.9-.77-3.75-.77-.84 0-3.24.43-3.24 2.83.01 1.7 1.85 1.84 2.42 1.9 1.9.27 3.87.63 5.78.82 4.1.49 7.83 1.96 7.56 5.56-.39 5.19-6.85 10.19-15.9 10.22-8.06.02-11.65-2.52-12.42-5 0 0 13.57 3.26 14.47-2.43.5-6.3-16.4.2-15.58-10.6.4-5.08 5.73-6.64 8.51-7.57a30.24 30.24 0 0 1 8.95-1.37c10.57-.03 16.14 2.65 14.85 8.42-2.97-.02-5.89.05-11.28.06"
          ></path>
          <path
            id="vbcdl"
            d="M231.85 23.34S221.7 20.9 218.6 29.5l-4.05 11.84-12.41.04 6.48-18.7 1.49-7.53 11.06-.03-.1 3.3h.22s4.13-2.74 5.96-3.4c2.99-1.04 7.64-.61 7.64-.61z"
          ></path>
          <path
            id="vbcdm"
            d="M282.16 29.54c-3.19 11.6 18.44 5.51 18.44 5.51s-4.22 6.04-16.4 6.45c-4.58-.2-16.16-1.26-16.18-11.22-.03-5.86 6.84-16.04 21.47-16.09 0 0 21.66-.07 13.76 15.28zm6.51-9.28s-4.6.01-5.77 6.07l8.92-.02c2.98-6.07-3.15-6.05-3.15-6.05z"
          ></path>
          <path id="vbcdn" d="M186.48 45.72h-3.28v-1.19h7.99v1.19h-3.3v9.6h-1.4z"></path>
          <path
            id="vbcdo"
            d="M195.67 44.67c.7-.14 1.71-.22 2.67-.22 1.5 0 2.45.27 3.12.88.55.48.85 1.22.85 2.05 0 1.42-.9 2.37-2.03 2.75v.05c.83.29 1.33 1.06 1.58 2.18.36 1.5.61 2.54.84 2.96h-1.44c-.18-.32-.42-1.23-.72-2.58-.32-1.49-.9-2.05-2.16-2.1h-1.32v4.68h-1.39zm1.4 4.92h1.42c1.49 0 2.43-.82 2.43-2.05 0-1.4-1-2-2.48-2.02-.67 0-1.15.07-1.38.13z"
          ></path>
          <path
            id="vbcdp"
            d="M209.4 51.92l-1.12 3.4h-1.44l3.67-10.79h1.68l3.68 10.79h-1.5l-1.14-3.4zm3.54-1.08l-1.06-3.1c-.24-.71-.4-1.35-.56-1.98h-.03c-.16.64-.34 1.3-.54 1.96l-1.06 3.12z"
          ></path>
          <path
            id="vbcdq"
            d="M229.77 50.58c-.08-1.5-.18-3.31-.16-4.66h-.05a60.53 60.53 0 0 1-1.36 4.1l-1.9 5.23h-1.06l-1.75-5.13a48.3 48.3 0 0 1-1.24-4.2h-.04c-.03 1.35-.1 3.16-.2 4.77l-.3 4.63h-1.32l.75-10.79h1.78l1.84 5.22c.45 1.33.82 2.51 1.09 3.63h.05c.27-1.09.65-2.27 1.13-3.63l1.92-5.22h1.78l.67 10.79h-1.36z"
          ></path>
          <path
            id="vbcdr"
            d="M236.84 44.66c.67-.11 1.55-.21 2.67-.21 1.38 0 2.39.32 3.03.9.59.5.94 1.3.94 2.25 0 .98-.28 1.75-.83 2.3-.73.8-1.94 1.2-3.3 1.2-.41 0-.8-.02-1.12-.1v4.32h-1.39zm1.4 5.2c.3.08.68.11 1.14.11 1.69 0 2.71-.81 2.71-2.3 0-1.43-1-2.12-2.54-2.12-.61 0-1.08.05-1.32.12z"
          ></path>
          <path id="vbcda" d="M248.01 44.35h9.86v11.14h-9.86z"></path>
          <path
            id="vbcdt"
            d="M257.87 49.8c0 3.72-2.26 5.7-5.01 5.7-2.85 0-4.85-2.22-4.85-5.48 0-3.43 2.13-5.67 5-5.67 2.96 0 4.86 2.26 4.86 5.46m-8.37.17c0 2.31 1.25 4.38 3.44 4.38 2.2 0 3.46-2.04 3.46-4.49 0-2.14-1.12-4.38-3.44-4.38-2.3 0-3.46 2.13-3.46 4.5"
          ></path>
          <path id="vbcdu" d="M262.97 44.53h1.4v9.62h4.6v1.17h-6z"></path>
          <path id="vbcdb" d="M0 55.5h312.77V0H0z"></path>
          <path id="vbcdw" d="M273.82 55.32h1.4V44.53h-1.4z"></path>
          <path
            id="vbcdx"
            d="M280.94 55.32V44.53h1.52l3.46 5.46c.8 1.26 1.42 2.4 1.93 3.5l.04-.01a47.98 47.98 0 0 1-.16-4.44v-4.51h1.3v10.79h-1.4l-3.43-5.48a34.24 34.24 0 0 1-2.01-3.6l-.05.02c.08 1.36.11 2.66.11 4.45v4.6z"
          ></path>
          <path
            id="vbcdy"
            d="M300.35 50.26h-4.2v3.89h4.68v1.17h-6.07V44.53h5.83v1.17h-4.44v3.4h4.2z"
          ></path>
          <path
            id="vbcdz"
            d="M309.57 17.69a3.17 3.17 0 0 1-3.22-3.17 3.16 3.16 0 0 1 3.22-3.12 3.14 3.14 0 1 1 0 6.29zm.02-.67a2.4 2.4 0 0 0 2.38-2.48c0-1.39-1.01-2.51-2.4-2.51a2.43 2.43 0 0 0-2.42 2.5c0 1.38 1.03 2.49 2.44 2.49zm-.52-.86h-.72v-3.12c.28-.06.68-.1 1.2-.1.59 0 .85.1 1.08.23.17.13.3.38.3.69 0 .34-.26.6-.64.72v.04c.3.11.48.34.57.76.1.48.15.67.23.78h-.78c-.1-.11-.15-.4-.25-.76-.05-.34-.24-.5-.65-.5h-.34zm.02-1.77h.34c.4 0 .73-.13.73-.46 0-.28-.21-.47-.67-.47-.19 0-.32.02-.4.04z"
          ></path>
          <clipPath id="vbcds">
            <use xlinkHref="#vbcda"></use>
          </clipPath>
          <clipPath id="vbcdv">
            <use xlinkHref="#vbcdb"></use>
          </clipPath>
        </defs>
        <g>
          <g>
            <g>
              <use xlinkHref="#vbcdc"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdd"></use>
            </g>
            <g>
              <use xlinkHref="#vbcde"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdf"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdg"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdh"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdi"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdj"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdk"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdl"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdm"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdn"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdo"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdp"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdq"></use>
            </g>
            <g>
              <use xlinkHref="#vbcdr"></use>
            </g>
            <g>
              <g></g>
              <g clipPath="url(#vbcds)">
                <use xlinkHref="#vbcdt"></use>
              </g>
            </g>
            <g>
              <use xlinkHref="#vbcdu"></use>
            </g>
            <g></g>
            <g clipPath="url(#vbcdv)">
              <use xlinkHref="#vbcdw"></use>
            </g>
            <g clipPath="url(#vbcdv)">
              <use xlinkHref="#vbcdx"></use>
            </g>
            <g clipPath="url(#vbcdv)">
              <use xlinkHref="#vbcdy"></use>
            </g>
            <g clipPath="url(#vbcdv)">
              <use xlinkHref="#vbcdz"></use>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default PlaqueLogo;
