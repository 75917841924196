import React, { Component } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import '../Styles/img.style.css';

export default class Img extends Component {
  state = {
    loaded: false,
    notification: false,
  };

  componentDidUpdate(prevProp) {
    const { imgUrl } = this.props;
    if (prevProp.imgUrl !== imgUrl) {
      this.setState({
        loaded: false,
      });
    }
  }

  /**
   * Show notification message when the image is loaded
   */
  showNotification() {
    this.setState({
      notification: true,
    });
    setTimeout(() => {
      this.setState({
        notification: false,
      });
    }, 3000);
  }

  /**
   * Render notification bubble
   */
  renderNotification() {
    if (this.state.notification) {
      return <div className="notification">Image updated.</div>;
    }

    return null;
  }

  render() {
    let { zoom, imgUrl } = this.props;

    /**
     * if zoom is not defined it should be true
     * and if there is no product, make it false
     */
    if (zoom === undefined) {
      zoom = true;
    }

    return (
      <div className="main-image">
        <div
          className="main-image__wrapper"
          style={{
            opacity: this.state.loaded ? 1 : 0.4,
          }}
        >
          {zoom && (
            <InnerImageZoom className="main-image--source" src={imgUrl} fullscreenOnMobile={true} />
          )}

          <div className="main-image--source">
            <img
              className="trampoline__image"
              src={imgUrl}
              alt=""
              onLoad={() => {
                this.setState({ loaded: true });
              }}
              style={{ display: zoom ? 'none' : 'block' }}
            />
          </div>
        </div>
        {!this.state.loaded && (
          <img className="product__image--loader" src="/img/loader.gif" alt="loading..." />
        )}
      </div>
    );
  }
}
