import { colors } from "../../../../helpers/trampolineColors";

export const themes = [
  {
    id: "theNationalistUS",
    name: "The USA",
    colors: [colors.red, colors.white, colors.blue],
    description: "Inspired by American pride.",
    location: ["us"],
    spec: {
      L: {
        arrangement: 1,
        l1: colors.red,
        l2: "000000",
        l3: "000000",
        l4: "000000",
        l5: "000000",
        l6: "000000",
      },
      F: {
        arrangement: 1,
        f1: colors.blue,
        f2: "000000",
        f3: "000000",
        f4: "000000",
        f5: "000000",
        f6: "000000",
      },
      M: {
        arrangement: "21",
        m1: colors.white,
        m2: colors.red,
        m3: "000000",
        m4: "000000",
        m5: "000000",
        m6: "000000",
      },
      E: {
        arrangement: 1,
        e1: colors.blue,
        e2: "000000",
        e3: "000000",
        e4: "000000",
        e5: "000000",
        e6: "000000",
      },
    },
    fontDetail: {
      activeFont: "BiffBamBoom",
      fontText: "",
      fontSize: 22,
      lineOne: "USA",
      lineTwo: "",
      color: "ffffff",
      plaqueColor: "F11D4A",
      isCustom: true,
      fromUrl: false,
    },
  },
  {
    id: "theNationalistCa",
    name: "Go Canada Go",
    colors: [colors.red, colors.white],
    description: "Inspired by the great white north.",
    location: ["ca"],
    spec: {
      L: {
        arrangement: 1,
        l1: colors.red,
        l2: "000000",
        l3: "000000",
        l4: "000000",
        l5: "000000",
        l6: "000000",
      },
      F: {
        arrangement: 1,
        f1: colors.white,
        f2: "000000",
        f3: "000000",
        f4: "000000",
        f5: "000000",
        f6: "000000",
      },
      M: {
        arrangement: 1,
        m1: colors.white,
        m2: "000000",
        m3: "000000",
        m4: "000000",
        m5: "000000",
        m6: "000000",
      },
      E: {
        arrangement: 1,
        e1: colors.red,
        e2: "000000",
        e3: "000000",
        e4: "000000",
        e5: "000000",
        e6: "000000",
      },
    },
    fontDetail: {
      activeFont: "BiffBamBoom",
      fontText: "",
      fontSize: 22,
      lineOne: "CANADA",
      lineTwo: "",
      color: "ffffff",
      plaqueColor: "ffffff",
      isCustom: true,
      fromUrl: false,
    },
  },
  {
    id: "theModernist",
    name: "The Modernist",
    colors: [colors.blue, colors.gunmetal],
    description: "Inspired by sleek modern design.",
    location: ["us", "ca"],
    spec: {
      L: {
        arrangement: 1,
        l1: colors.gunmetal,
        l2: "000000",
        l3: "000000",
        l4: "000000",
        l5: "000000",
        l6: "000000",
      },
      F: {
        arrangement: 1,
        f1: colors.gunmetal,
        f2: "000000",
        f3: "000000",
        f4: "000000",
        f5: "000000",
        f6: "000000",
      },
      M: {
        arrangement: 1,
        m1: colors.blue,
        m2: "000000",
        m3: "000000",
        m4: "000000",
        m5: "000000",
        m6: "000000",
      },
      E: {
        arrangement: 1,
        e1: colors.gunmetal,
        e2: "000000",
        e3: "000000",
        e4: "000000",
        e5: "000000",
        e6: "000000",
      },
    },
    fontDetail: {
      activeFont: "Atrament",
      fontText: "",
      fontSize: 22,
      lineOne: "MODERNIST",
      lineTwo: "",
      color: "ffffff",
      plaqueColor: "ffffff",
      isCustom: true,
      fromUrl: false,
    },
  },
  {
    id: "prettyPink",
    name: "Pretty in Pink",
    colors: [colors.pink, colors.white],
    description: "Inspired by the love of all things pink.",
    location: ["us", "ca"],
    spec: {
      L: {
        arrangement: 1,
        l1: colors.pink,
        l2: "000000",
        l3: "000000",
        l4: "000000",
        l5: "000000",
        l6: "000000",
      },
      F: {
        arrangement: 1,
        f1: colors.white,
        f2: "000000",
        f3: "000000",
        f4: "000000",
        f5: "000000",
        f6: "000000",
      },
      M: {
        arrangement: "21",
        m1: colors.white,
        m2: colors.pink,
        m3: "000000",
        m4: "000000",
        m5: "000000",
        m6: "000000",
      },
      E: {
        arrangement: "21",
        e1: colors.white,
        e2: colors.pink,
        e3: "000000",
        e4: "000000",
        e5: "000000",
        e6: "000000",
      },
    },
    fontDetail: {
      activeFont: "SignPainter",
      fontText: "",
      fontSize: 28,
      lineOne: "Pink",
      lineTwo: "",
      color: "e2a3ae",
      plaqueColor: "e2a3ae",
      isCustom: true,
      fromUrl: false,
    },
  },
  {
    id: "theOutdoor",
    name: "The Naturalist",
    colors: [colors.green, colors.blue],
    description: "Inspired by earth’s oceans and forests.",
    location: ["us", "ca"],
    spec: {
      L: {
        arrangement: 1,
        l1: colors.green,
        l2: "000000",
        l3: "000000",
        l4: "000000",
        l5: "000000",
        l6: "000000",
      },
      F: {
        arrangement: 1,
        f1: colors.green,
        f2: "000000",
        f3: "000000",
        f4: "000000",
        f5: "000000",
        f6: "000000",
      },
      M: {
        arrangement: 1,
        m1: colors.blue,
        m2: "000000",
        m3: "000000",
        m4: "000000",
        m5: "000000",
        m6: "000000",
      },
      E: {
        arrangement: 1,
        e1: colors.blue,
        e2: "000000",
        e3: "000000",
        e4: "000000",
        e5: "000000",
        e6: "000000",
      },
    },
    fontDetail: {
      activeFont: "ClassicComic",
      fontText: "",
      fontSize: 13,
      lineOne: "I ❤ NATURE",
      lineTwo: "",
      color: "0053A5",
      plaqueColor: "0053A5",
      isCustom: true,
      fromUrl: false,
    },
  },
  {
    id: "superHero",
    name: "The Super Hero",
    colors: [colors.blue, colors.red],
    description: "Inspired by the hero inside us all.",
    location: ["us", "ca"],
    spec: {
      L: {
        arrangement: 1,
        l1: colors.blue,
        l2: "000000",
        l3: "000000",
        l4: "000000",
        l5: "000000",
        l6: "000000",
      },
      F: {
        arrangement: 1,
        f1: colors.red,
        f2: "000000",
        f3: "000000",
        f4: "000000",
        f5: "000000",
        f6: "000000",
      },
      M: {
        arrangement: 1,
        m1: colors.blue,
        m2: "000000",
        m3: "000000",
        m4: "000000",
        m5: "000000",
        m6: "000000",
      },
      E: {
        arrangement: 1,
        e1: colors.red,
        e2: "000000",
        e3: "000000",
        e4: "000000",
        e5: "000000",
        e6: "000000",
      },
    },
    fontDetail: {
      activeFont: "BiffBamBoom",
      fontText: "",
      fontSize: 22,
      lineOne: "SUPER",
      lineTwo: "",
      color: "f1ea24",
      plaqueColor: "ec3232",
      isCustom: true,
      fromUrl: false,
    },
  },
];
