import React from 'react';
import { accessoryData } from '../Accessories/data';

const SelectedAccessories = ({ accessoryList, location, productId }) => {
  if (accessoryList.length < 1) {
    return null;
  }

  return (
    <div className="order__accessories--wrapper">
      <h3 className="order__accessories--heading">Accessories</h3>
      {accessoryList.map((accessory,index) => {
        const accessoryItem = accessoryData.find(acc => acc.id === accessory);
        return (
          <div className="order__accessories" key={index}>
            <span className="order__accessories--name">{accessoryItem.name}</span>
            <span className="order__accessories--price">{accessoryItem[location][productId]}</span>
          </div>
        );
      })}
    </div>
  );
};

export default SelectedAccessories;
