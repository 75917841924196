import React from "react";

const Covid19Message = ({ small }) => {
  return (
    <p
      style={{
        color: "#F63006",
        fontWeight: small ? "normal" : "bold",
        fontSize: small ? "inherit" : "1.3rem",
        fontStyle: small ? "italic" : "normal",
        maxWidth: small ? 350 : "auto",
      }}
    >
      Design now and purchase your trampoline when stock arrives. After you save
      your design, we will contact you within 3 business days to arrange details
      and timing.
    </p>
  );
};

export default Covid19Message;
