export const arrangement = {
  S155: {
    L: {
      1: 0,
      21: 1,
      22: 2,
      23: 4,
      41: 1,
    },
    F: {
      1: 0,
      21: 1,
      22: 4,
      41: 1,
      42: 2,
    },
    M: {
      1: 0,
      21: 1,
      22: 2,
      23: 19,
      24: 38,
      41: 1,
      42: 19,
    },
    E: {
      1: 0,
      21: 1,
      22: 2,
      23: 6,
      31: 1,
      32: 2,
      33: 4,
      41: 1,
      42: 3,
      61: 1,
      62: 2,
    },
  },
  S113: {
    L: {
      1: 0,
      21: 1,
      22: 2,
      23: 4,
      41: 1,
    },
    F: {
      1: 0,
      21: 1,
      22: 4,
      41: 1,
      42: 2,
    },
    M: {
      1: 0,
      21: 1,
      22: 2,
      23: 4,
      24: 8,
      25: 16,
      26: 29,
      41: 1,
      42: 2,
      43: 4,
      44: 8,
      45: 16,
    },
    E: {
      1: 0,
      21: 1,
      22: 2,
      23: 6,
      31: 1,
      32: 2,
      33: 4,
      41: 1,
      42: 3,
      61: 1,
      62: 2,
    },
  },
  O92: {
    L: {
      1: 0,
      21: 1,
      22: 3,
      31: 1,
      32: 2,
      61: 1,
    },
    F: {
      1: 0,
      21: 1,
      22: 3,
      31: 1,
      32: 2,
      61: 1,
    },
    M: {
      1: 0,
      21: 1,
      22: 29,
    },
    E: {
      1: 0,
      21: 1,
      22: 5,
      51: 1,
      52: 2,
    },
  },
  O77: {
    L: {
      1: 0,
      21: 1,
      22: 3,
      31: 1,
      32: 2,
      61: 1,
    },
    F: {
      1: 0,
      21: 1,
      22: 3,
      31: 1,
      32: 2,
      61: 1,
    },
    M: {
      1: 0,
      21: 1,
      22: 2,
      23: 13,
      24: 26,
      41: 1,
      42: 13,
    },
    E: {
      1: 0,
      21: 1,
      22: 5,
      51: 1,
      52: 2,
    },
  },
};
