import { productData } from '../Components/Screens/Data';
import colorLabels from './trampolineColors';

/**
 * Defaut values of parts
 */
const partsPlacement = ['L', 'F', 'M', 'E'];
let parts = [
  {
    id: 'S155',
    data: {
      L: {
        label: 'Legs',
        sequence: ['SP0031', 'SP0031', 'SP0031', 'SP0031', 'SP0031', 'SP0031', 'SP0031', 'SP0031'],
      },
      F: {
        label: 'Frame',
        sequence: ['SP0027', 'SP0024', 'SP0027', 'SP0024', 'SP0027', 'SP0024', 'SP0027', 'SP0024'],
      },
      M: {
        label: 'Mat rods',
        sequence: new Array(76).fill('SP0032'),
      },
      E: {
        label: 'Enclosure rods',
        sequence: new Array(12).fill('SP0035'),
      },
    },
  },
  {
    id: 'S113',
    data: {
      L: {
        label: 'Legs',
        sequence: ['SP0030', 'SP0030', 'SP0030', 'SP0030', 'SP0030', 'SP0030', 'SP0030', 'SP0030'],
      },
      F: {
        label: 'Frame',
        sequence: ['SP0026', 'SP0024', 'SP0026', 'SP0024', 'SP0026', 'SP0024', 'SP0026', 'SP0024'],
      },
      M: {
        label: 'Mat rods',
        sequence: new Array(64).fill('SP0032'),
      },
      E: {
        label: 'Enclosure rods',
        sequence: new Array(12).fill('SP0035'),
      },
    },
  },
  {
    id: 'O92',
    data: {
      L: {
        label: 'Legs',
        sequence: ['SP0030', 'SP0030', 'SP0029', 'SP0030', 'SP0030', 'SP0029'],
      },
      F: {
        label: 'Frame',
        sequence: ['SP0027', 'SP0024', 'SP0024', 'SP0027', 'SP0024', 'SP0024'],
      },
      M: {
        label: 'Mat rods',
        sequence: new Array(58).fill('SP0032'),
      },
      E: {
        label: 'Enclosure rods',
        sequence: new Array(10).fill('SP0035'),
      },
    },
  },
  {
    id: 'O77',
    data: {
      L: {
        label: 'Legs',
        sequence: ['SP0030', 'SP0030', 'SP0029', 'SP0030', 'SP0030', 'SP0029'],
      },
      F: {
        label: 'Frame',
        sequence: ['SP0026', 'SP0024', 'SP0024', 'SP0026', 'SP0024', 'SP0024'],
      },
      M: {
        label: 'Mat rods',
        sequence: new Array(52).fill('SP0032'),
      },
      E: {
        label: 'Enclosure rods',
        sequence: new Array(10).fill('SP0035'),
      },
    },
  },
];

/**
 * make key value pair of the product id count for reference
 * @param {array} referenceList
 * @return {object} - with object with key and value pair of product ids
 */
const getOrderReference = (referenceList, partId) => {
  let referenceObject = {};
  let referenceSequece = {};
  referenceList.forEach((part, index) => {
    referenceSequece[`${partId.toUpperCase()}${index + 1}`] = part;
    if (!referenceObject[part]) {
      referenceObject[part] = 1;
    } else {
      referenceObject[part] += 1;
    }
  });

  return { referenceObject, referenceSequece };
};

/**
 * Gets part arrangment and label from product's part
 * @param {string} productId - id of the product
 * @param {object} partSpec - information of the product
 */
export const partArrangements = (productId, partSpec) => {
  // Find the product with the id
  const targetProduct = productData.find(prod => prod.id === productId);
  if (!targetProduct) {
    return null;
  }

  const targetProductParts = parts.find(prod => prod.id === productId);
  // loop through all the parts of a trampoline
  partsPlacement.forEach(partId => {
    const targetSequence = targetProductParts.data[partId].sequence;
    /**
     * Split the arrangement to get number of colors used
     * if there is a second parameter
     * that will be the color arrangement
     */
    const partArrangementValue = partSpec[partId].arrangement.toString().split('');

    if (partArrangementValue.length > 1) {
      const colorArrangement = partArrangementValue[1];
      /**
       * get the value of the arrangement from the product details
       * find the part to get option
       */
      const targetPart = targetProduct.data.find(val => val.id === partId);
      // get the options from product part config
      const arrangementOptions = targetPart.config.find(
        conf => conf.id === partArrangementValue[0]
      );

      // subtract 1 to point to right array index
      const arrangementValue = arrangementOptions.options[colorArrangement - 1];
      const arrangementValueId = arrangementValue.id;

      let updatedSequence = []; // part item code in sequence
      let partSequence = {}; // color of each part
      let arrangementColor = [];
      for (let c = 0;c < parseInt(partArrangementValue[0]);c++) {
        arrangementColor.push(colorLabels[partSpec[partId][`${partId.toLowerCase()}${c + 1}`]]);
      }
      let colorIndex = 0;

      switch (arrangementValueId) {
        // one color alternating
        case 'AL1A':
          for (let index = 0;index < targetSequence.length;index++) {
            updatedSequence.push(
              `${targetSequence[index]}-${arrangementColor[colorIndex].toUpperCase()}`
            );
            partSequence[`${partId}${index + 1}`] = arrangementColor[colorIndex];

            /**
             * if it is the last color in the list
             * else switch to the next color
             */
            if (colorIndex === arrangementColor.length - 1) {
              colorIndex = 0;
            } else {
              colorIndex += 1;
            }
          }
          break;

        case 'HH00':
          for (let index = 0;index < targetSequence.length;index++) {
            updatedSequence.push(
              `${targetSequence[index]}-${arrangementColor[colorIndex].toUpperCase()}`
            );
            partSequence[`${partId}${index + 1}`] = arrangementColor[colorIndex];

            /**
             * add first color to the half of the parts
             * after that switch to the next color for the other half
             */
            if (index === targetSequence.length / 2 - 1) {
              colorIndex += 1;

              // reset if color index doesn't exist
              if (!arrangementColor[colorIndex]) {
                colorIndex = 0;
              }
            }
          }
          break;

        case 'AL2A':
          for (let index = 0;index < targetSequence.length;index++) {
            updatedSequence.push(
              `${targetSequence[index]}-${arrangementColor[colorIndex].toUpperCase()}`
            );
            partSequence[`${partId}${index + 1}`] = arrangementColor[colorIndex];

            /**
             * add the color 2 times
             * switch to the next after 2
             * reset the index
             */
            if ((index + 1) % 2 === 0) {
              colorIndex += 1;
              // reset if color index doesn't exist
              if (!arrangementColor[colorIndex]) {
                colorIndex = 0;
              }
            }
          }
          break;

        case 'AL3A':
          for (let index = 0;index < targetSequence.length;index++) {
            updatedSequence.push(
              `${targetSequence[index]}-${arrangementColor[colorIndex].toUpperCase()}`
            );
            partSequence[`${partId}${index + 1}`] = arrangementColor[colorIndex];

            /**
             * add the color 3 times
             * switch to the next after 3
             */
            if ((index + 1) % 3 === 0) {
              colorIndex += 1;

              // reset if color index doesn't exist
              if (!arrangementColor[colorIndex]) {
                colorIndex = 0;
              }
            }
          }
          break;

        case 'AL4A':
          for (let index = 0;index < targetSequence.length;index++) {
            updatedSequence.push(
              `${targetSequence[index]}-${arrangementColor[colorIndex].toUpperCase()}`
            );
            partSequence[`${partId}${index + 1}`] = arrangementColor[colorIndex];

            /**
             * add the color 4 times
             * switch to the next after 4
             */
            if ((index + 1) % 4 === 0) {
              colorIndex += 1;

              // reset if color index doesn't exist
              if (!arrangementColor[colorIndex]) {
                colorIndex = 0;
              }
            }
          }
          break;

        case 'AL8A':
          for (let index = 0;index < targetSequence.length;index++) {
            updatedSequence.push(
              `${targetSequence[index]}-${arrangementColor[colorIndex].toUpperCase()}`
            );
            partSequence[`${partId}${index + 1}`] = arrangementColor[colorIndex];

            /**
             * add the color 8 times
             * switch to the next after 8
             */
            if ((index + 1) % 8 === 0) {
              colorIndex += 1;

              // reset if color index doesn't exist
              if (!arrangementColor[colorIndex]) {
                colorIndex = 0;
              }
            }
          }
          break;

        case 'AL13':
          for (let index = 0;index < targetSequence.length;index++) {
            updatedSequence.push(
              `${targetSequence[index]}-${arrangementColor[colorIndex].toUpperCase()}`
            );
            partSequence[`${partId}${index + 1}`] = arrangementColor[colorIndex];

            /**
             * add the color 13 times
             * switch to the next after 13
             */
            if ((index + 1) % 13 === 0) {
              colorIndex += 1;

              // reset if color index doesn't exist
              if (!arrangementColor[colorIndex]) {
                colorIndex = 0;
              }
            }
          }
          break;

        case 'AL16':
          for (let index = 0;index < targetSequence.length;index++) {
            updatedSequence.push(
              `${targetSequence[index]}-${arrangementColor[colorIndex].toUpperCase()}`
            );
            partSequence[`${partId}${index + 1}`] = arrangementColor[colorIndex];

            /**
             * add the color 16 times
             * switch to the next after 16
             */
            if ((index + 1) % 16 === 0) {
              colorIndex += 1;

              // reset if color index doesn't exist
              if (!arrangementColor[colorIndex]) {
                colorIndex = 0;
              }
            }
          }
          break;

        case 'AL19':
          for (let index = 0;index < targetSequence.length;index++) {
            updatedSequence.push(
              `${targetSequence[index]}-${arrangementColor[colorIndex].toUpperCase()}`
            );
            partSequence[`${partId}${index + 1}`] = arrangementColor[colorIndex];

            /**
             * add the color 19 times
             * switch to the next after 19
             * reset if the color starts repeating
             */
            if ((index + 1) % 19 === 0) {
              colorIndex += 1;

              // reset if color index doesn't exist
              if (!arrangementColor[colorIndex]) {
                colorIndex = 0;
              }
            }
          }
          break;

        case 'RA00':
          // this will return for random, filling every color once
          for (let index = 0;index < targetSequence.length;index++) {
            // if the colorIndex goes over the count
            if (arrangementColor.length === colorIndex) {
              break;
            }
            updatedSequence.push(
              `${targetSequence[index]}-${arrangementColor[colorIndex].toUpperCase()}`
            );
            partSequence[`${partId}${index + 1}`] = arrangementColor[colorIndex];

            /**
             * switch color everytime
             */
            colorIndex += 1;

            // reset if color index doesn't exist
            if (!arrangementColor[colorIndex]) {
              colorIndex = 0;
            }
          }
          break;

        default:
          break;
      }

      targetProductParts.data[partId].orderReference = getOrderReference(updatedSequence, partId);
      targetProductParts.data[partId].installationReference = partSequence;
    }

    /**
     * If there is only one color arrangement
     * loop through the parts sequence
     * add color to it
     */
    if (partArrangementValue.length === 1) {
      let updatedSequence = []; // part item code in sequence
      let partSequence = {}; // color of each part
      targetSequence.forEach((partProductId, index) => {
        const colorValue = colorLabels[partSpec[partId][`${partId.toLowerCase()}1`]];

        updatedSequence.push(`${partProductId}-${colorValue.toUpperCase()}`);
        partSequence[`${partId}${index + 1}`] = colorValue;
      });

      targetProductParts.data[partId].orderReference = getOrderReference(updatedSequence, partId);
      targetProductParts.data[partId].installationReference = partSequence;
    }
  });

  return targetProductParts.data;
};
