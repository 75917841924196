import { parseSpecialCharacter } from './parseSpecialCharacter';

/**
 * @param {object} config - variables of the product to generate url
 * @return {string} - liquidpixel url of the image
 */
export const getImageUrl = config => {
  let { L, F, M, E, productId, fontDetail } = config;
  // if there are not font details, load with default values
  if (!fontDetail) {
    fontDetail = {
      activeFont: 'MeatBall',
      fontText: '',
      fontSize: 14,
      lineOne: '',
      lineTwo: '',
      color: 'ffffff',
      plaqueColor: 'ffffff',
      isCustom: false,
    };
  }

  // status to check if the plaque is customized or default
  const plaqueType = fontDetail.isCustom ? 1 : 0;

  // update line one and line two with special characters
  let lineOne = parseSpecialCharacter(fontDetail.lineOne);
  let lineTwo = parseSpecialCharacter(fontDetail.lineTwo);

  /**
   * structure image url
   * load placeholder by default
   * if there is a product selected, load that
   */
  let imgUrl = '/img/main.jpg';
  let plaqueLogoColor = fontDetail.plaqueColor;
  // if ( location === 'CA') { plaqueLogoColor === 'ffffff'; }
  if (productId) {
    imgUrl =
      'https://gsg.liquifire.com/gsg?set=EA%5B' +
      E.arrangement +
      '%5D,FA%5B' +
      F.arrangement +
      '%5D,LA%5B' +
      L.arrangement +
      '%5D,MA%5B' +
      M.arrangement +
      '%5D,PC%5B%23' +
      fontDetail.color +
      '%5D,LC%5B%23' +
      plaqueLogoColor +
      '%5D,PF%5B' +
      fontDetail.activeFont +
      '%5D,PS%5B' +
      fontDetail.fontSize +
      '%5D,PT1%5B' +
      lineOne +
      '%5D,PT2%5B' +
      lineTwo +
      '%5D,PU%5B' +
      plaqueType +
      '%5D,' +
      'e1%5B%23' +
      E.e1 +
      '%5D,e2%5B%23' +
      E.e2 +
      '%5D,e3%5B%23' +
      E.e3 +
      '%5D,e4%5B%23' +
      E.e4 +
      '%5D,e5%5B%23' +
      E.e5 +
      '%5D,e6%5B%23' +
      E.e6 +
      '%5D,f1%5B%23' +
      F.f1 +
      '%5D,f2%5B%23' +
      F.f2 +
      '%5D,f3%5B%23' +
      F.f3 +
      '%5D,f4%5B%23' +
      F.f4 +
      '%5D,f5%5B%23' +
      F.f5 +
      '%5D,f6%5B%23' +
      F.f6 +
      '%5D,l1%5B%23' +
      L.l1 +
      '%5D,l2%5B%23' +
      L.l2 +
      '%5D,l3%5B%23' +
      L.l3 +
      '%5D,l4%5B%23' +
      L.l4 +
      '%5D,l5%5B%23' +
      L.l5 +
      '%5D,l6%5B%23' +
      L.l6 +
      '%5D,m1%5B%23' +
      M.m1 +
      '%5D,m2%5B%23' +
      M.m2 +
      '%5D,m3%5B%23' +
      M.m3 +
      '%5D,m4%5B%23' +
      M.m4 +
      '%5D,m5%5B%23' +
      M.m5 +
      '%5D,m6%5B%23' +
      M.m6 +
      '%5D,prodID%5B' +
      productId +
      '%5D,' +
      'logo1%5Bfile:/ith/Product/' +
      productId +
      '/Plaque/default.png%5D,version%5Bweb%5D&set=tables%5Bfile:tables/%5D&set=apps%5Bfile:apps/%5D&call=url%5B(%27global.apps%27%20.%20%27config4%27%20.%20%27global.version%27)%5D&scale=size%5B2500%5D&brightness=value%5B120%5D&sink';
  }

  return imgUrl;
};
