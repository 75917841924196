/**
 * Converts special characters in string
 * @param {string} stringToConvert
 */
export const parseSpecialCharacter = stringToConvert => {
  stringToConvert = stringToConvert.replace(/'/g, 'U0027');
  stringToConvert = stringToConvert.replace(/‘/g, 'U2018');
  stringToConvert = stringToConvert.replace(/’/g, 'U2019');
  stringToConvert = stringToConvert.replace(/"/g, 'U0022');
  stringToConvert = stringToConvert.replace(/“/g, 'U201C');
  stringToConvert = stringToConvert.replace(/”/g, 'U201D');
  stringToConvert = stringToConvert.replace(/,/g, 'U002C');
  stringToConvert = stringToConvert.replace(/\./g, 'U002E');
  stringToConvert = stringToConvert.replace(/\*/g, 'U002A');
  stringToConvert = stringToConvert.replace(/ /g, '%20');

  return stringToConvert;
};

export const resetSpecialCharacter = stringToConvert => {
  stringToConvert = stringToConvert.replace(/U0027/g, "'");
  stringToConvert = stringToConvert.replace(/U2018/g, '‘');
  stringToConvert = stringToConvert.replace(/U2019/g, '’');
  stringToConvert = stringToConvert.replace(/U0022/g, '"');
  stringToConvert = stringToConvert.replace(/U201C/g, '“');
  stringToConvert = stringToConvert.replace(/U201D/g, '”');
  stringToConvert = stringToConvert.replace(/U002C/g, ',');
  stringToConvert = stringToConvert.replace(/U002E/g, '.');
  stringToConvert = stringToConvert.replace(/U002A/g, '*');
  stringToConvert = stringToConvert.replace(/%20/g, ' ');

  return stringToConvert;
};
