import React, { Component } from 'react';
import './customTheme.style.css';
import Img from '../Img';
import ScrollView from '../../../ScrollView';
import { sliderSettings } from '../../../ScrollView/sliderSettings';
import { getImageUrl } from '../../../../helpers/imageUrl';
// import { spec } from '../../../../helpers/defaultSpec';

export default class ThemePicker extends Component {
  state = {
    click: true,
    activeIndex: 0,
  };

  render() {
    const { themes, selectTheme, productId, selectedTheme, location } = this.props;
    // filter out themes for the location
    const themesForLocation = themes.filter(th => th.location.includes(location));

    return (
      <div className="custom-theme__wrapper">
        <ScrollView
          heading="Signature Models"
          activeIndex={this.state.activeIndex}
          settings={sliderSettings(this)}
        >
          {themesForLocation.map((theme, index) => {
            const spec = theme.spec;
            const fontDetail = theme.fontDetail;
            let themeClass = 'custom-theme';
            if (selectedTheme && selectedTheme.name === theme.name) {
              themeClass += ' active';
            }
            return (
              <div
                className={themeClass}
                key={theme.name}
                onClick={() => {
                  // only update when the user is not dragging the slider
                  if (this.state.click) {
                    selectTheme(theme);
                  }
                }}
              >
                <div className="custom-theme__image">
                  <Img
                    imgUrl={getImageUrl({
                      ...spec,
                      fontDetail,
                      productId,
                    })}
                    zoom={false}
                  />
                </div>
                <div className="custom-theme__content">
                  <h3 className="custom-theme__heading">{theme.name}</h3>
                  <div>
                    {theme.colors.map((color, index) => {
                      return (
                        <span
                          className="steps__color"
                          key={`${color + index}`}
                          style={{
                            backgroundColor: `#${color}`,
                            marginLeft: index > 0 ? '-12px' : 0,
                          }}
                        />
                      );
                    })}
                  </div>
                  <p className="custom-theme__description">{theme.description}</p>
                </div>
              </div>
            );
          })}
        </ScrollView>
      </div>
    );
  }
}
