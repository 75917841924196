export const fontData = [
  {
    name: 'Atrament Web',
    value: 'atrament-web',
    urlLabel: 'Atrament',
  },
  {
    name: 'Sign Painter',
    value: 'SignPainter',
    urlLabel: 'SignPainter',
  },
  {
    name: 'Classic Comic',
    value: 'classic-comic',
    urlLabel: 'ClassicComic',
  },
  {
    name: 'Meatball',
    value: 'Meatball',
    urlLabel: 'MeatBall',
  },
  {
    name: 'Biff Bam Boom',
    value: 'CCBiffBamBoom',
    urlLabel: 'BiffBamBoom',
  },
];
