export const accessories = {
  S155: {
    flexHoop: "SP0079",
    flexrStep: "SP0181",
    sunshade: "SP0136",
    tgoma: "TGS155000",
    cover: "SP0129",
    groundAnchor: "G2JumboAnchorKit",
    shiftingWheels: "SP0122",
  },
  S113: {
    flexHoop: "SP0079",
    flexrStep: "SP0181",
    sunshade: "SP0135",
    tgoma: "TGS113000",
    cover: "SP0128",
    groundAnchor: "SP0280",
    shiftingWheels: "SP0122",
  },
  O92: {
    flexHoop: "SP0079",
    flexrStep: "SP0181",
    sunshade: "SP0134",
    tgoma: "TGO92000",
    cover: "SP0127",
    groundAnchor: "SP0280",
    shiftingWheels: "SP0122",
  },
  O77: {
    flexHoop: "SP0079",
    flexrStep: "SP0181",
    sunshade: "SP0133",
    tgoma: "TGO77000",
    cover: "SP0126",
    groundAnchor: "SP0280",
    shiftingWheels: "SP0122",
  },
};
