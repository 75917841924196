export const sliderSettings = self => {
  return {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 200,
    variableWidth: true,
    swipeToSlide: true,
    touchThreshold: 200,
    slidesToShow: 1,
    beforeChange: () => {
      self.setState({
        click: false,
      });
    },
    afterChange: newIndex => {
      self.setState({
        click: true,
        activeIndex: newIndex,
      });
    },
  };
};
