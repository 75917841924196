import React from 'react';

const ProductMessage = ({ status, left }) => {
  if (!status) return null;

  return (
    <span className={`status__message status__message--${status.code} status__message--${left ? 'left' : ''}`}>
      {status.message}
    </span>
  );
}

export default ProductMessage;